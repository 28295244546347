import M$plugin$IGNSearchLocator from './facade/js/ignsearchlocator';
import M$control$IGNSearchLocatorControl from './facade/js/ignsearchlocatorcontrol';
import M$impl$control$IGNSearchLocatorControl from './impl/ol/js/ignsearchlocatorcontrol';

if (!window.M.plugin) window.M.plugin = {};
if (!window.M.control) window.M.control = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.plugin.IGNSearchLocator = M$plugin$IGNSearchLocator;
window.M.control.IGNSearchLocatorControl = M$control$IGNSearchLocatorControl;
window.M.impl.control.IGNSearchLocatorControl = M$impl$control$IGNSearchLocatorControl;
